<template>
    <ion-page>
        <ion-header>
            <div class="cardTitle">
                <div class="title">筛选</div>
                <div class="right">
                    <span @click="closeModal()" class="iconfont icon-guanbi"></span>
                </div>
            </div>
        </ion-header>
        <ion-content>
            <div class="allTarget" slot="fixed">
                <div class="categoryBox">
                    <div
                            class="categoryItem"
                            :class="{cateSelected:load.isChecked}"
                            v-for="(load, index) in loadArr"
                            :key="index"
                            @click="changeCate(index)">
                        {{ load.text }}
                    </div>
                </div>
            </div>
            <div class="root">
                <div class="item">
                    <div class="left">试制任务单号</div>
                    <div class="right">
                        <input
                                type="text"
                                placeholder="请输入任务单号"
                                v-model="searchPanelForm.code"
                                @keyup="keyNameHandler($event)"
                        />
                    </div>
                </div>
                <div class="search">
                    <ion-button class="selectBtn" @click="confirm">确定</ion-button>
                    <ion-button
                            class="selectBtn"
                            @click="reset"
                            style="margin-left: 10px"
                    >重置</ion-button
                    >
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
    import { defineComponent } from "vue";
    import { IonMenu, menuController, modalController } from "@ionic/vue";
    import { useRouter } from "vue-router";
    import CardLoading from "@/components/ListCard/CardLoading.vue";
    import questionCard from "./questionCard.vue";
    import SearchBar from "@/components/SearchBar.vue";
    import Utils from "@/utils/Utils";
    import BaseService from "@/services/base/BaseService";
    import {getProblemList} from "@/api/questionManage/questionSearch"
    export default defineComponent({
        name: "questionSearch",
        props: {
            dimensionalityArr: Object,
            screenConditions: Object,
        },
        components: {
            SearchBar,
            IonMenu,
            questionCard,
            CardLoading,
        },
        data() {
            const router = useRouter();
            return {
                loadArr: [
                    { value: "1", text: "在装",isChecked:true,name:'isLoading', },
                    { value: "0", text: "结束",isChecked:false,name:'isEnd' },
                ],
                targetIndex: 0,
                router: router,
                mroeSubtitle: "",
                searchKey: "",
                islist: false, //是否显示加载中的标识
                taskList: [

                ],
                //----筛选条件字段--------------
                searchPanelForm: {
                    code: "", //任务单号
                },
                baseService: new BaseService(),
            };
        },
        mounted(){
            if(this.screenConditions){
                this.loadArr.forEach((item, index) => {
                    item.isChecked = this.screenConditions[item.name];
                });
                this.searchPanelForm.code = this.screenConditions.stageCode;
            }
        },
        methods: {
            beforeOpen (event) {
                this.item = event.params.item;
            },
            keyNameHandler(ev) {
                const keyCode = window.event ? ev.keyCode : ev.which;
                if (keyCode === 13) {
                    this.confirm();
                }
            },
            changeCate(idx){
                this.loadArr[idx].isChecked=!this.loadArr[idx].isChecked
            },

            closeModal() {
                modalController.dismiss();
            },
            getList(){
                const screens = {
                    stageCode:this.searchPanelForm.code,
                    isLoading:this.loadArr[0].isChecked==true?1:0,
                    isEnd:this.loadArr[1].isChecked==true?1:0,
                }
                this.params= {
                    stageCode:this.searchPanelForm.code,
                    isUnresolved:this.dimensionalityArr[0].isChecked==true?1:0,
                    isUnderSolution:this.dimensionalityArr[1].isChecked==true?1:0,
                    isResolved:this.dimensionalityArr[2].isChecked==true?1:0,
                    isCompromise:this.dimensionalityArr[3].isChecked==true?1:0,
                    isLoading:this.loadArr[0].isChecked==true?1:0,
                    isEnd:this.loadArr[1].isChecked==true?1:0,
                }
                getProblemList(this.params).then(res=>{
                    if(res){
                        modalController.dismiss({
                            isReturn: true,
                            screenConditions: screens,
                            taskList:res.data.data.resultData,
                        });
                    }
                })
            },

            openTaskInfo(event){
                this.$router.push({
                    path:'/questionView',
                    query:{
                        id:event.id
                    }
                })
            },
            //确定检索
            confirm() {
                if (Utils.isInCludeEmoji(this.searchPanelForm.code)) {
                    Utils.presentToastWarning("任务单号不可以包含表情");
                    return;
                }
                this.searchPanelForm.code = this.baseService.trim(
                    this.searchPanelForm.code
                );
                this.searchPanelForm.isSelected = "true";
                this.getList()
            },
            //重置检索条件
            reset() {
                this.searchPanelForm = {
                    code: "", //任务单号
                };
                this.loadArr[0].isChecked = true;
                this.loadArr[1].isChecked = false;
            },
        },
    });
</script>

<style scoped lang="scss">
    ion-button {
        --background: #00529c;
    }

    .star {
        color: red;
        padding-top: px2rem(10);
    }
    .root:last-child {
        margin-top: px2rem(100);
    }
    .root {
        width: calc(100% #{px2rem(58)});
        height: auto;
        margin: px2rem(20) auto;
        background: #ffffff;
        border-radius: $border-radius;
        box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
        padding: px2rem(20) px2rem(45);
        font-size: #{$default-font-size};
    }
    .lefttwo {
        width: px2rem(270) !important;
        height: auto !important;
        line-height: px2rem(38) !important;
        // text-align: left;
        color: $color !important;
    }
    .item {
        .title {
            width: 100% !important;
            font-size: px2rem(28) !important;
            font-weight: 700;
            ion-textarea {
                --placeholder-color: #8e8e8e;
                --placeholder-opacity: 1;
            }
        }
        .left {
            width: 30%;
            /*line-height: px2rem(88);*/
            text-align: left;
        }
        .right {
            width: 70%;
            height: px2rem(88);
            text-align: right;
            --color: black;
            line-height: px2rem(88);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            input {
                width: 100%;
                height: 30px;
                border: none;
            }
            .iconfont {
                margin-left: px2rem(25);
                font-size: px2rem(12);
                color: #325cab;
            }
            ion-icon {
                margin-top: px2rem(10);
                width: px2rem(26);
                height: px2rem(26);
                color: #325cab;
            }
        }
    }
    .multi-line {
        margin-top: 1rem;
        border: solid #d6e2ec;
        border-width: 0 0 1px 0;
        width: 100%;
        border-width: 0;
        text-align: left;
        .textarea {
            width: calc(100%-#{px2rem(24)+3rem});
            --padding-top: 0;
            margin-left: 3rem;
            --color: black;
        }
    }
    .moreproject {
        text-align: center;
        font-size: px2rem(24px);
        color: #325cab;
        margin-top: px2rem(20px);
    }

    .remark {
        max-height: 280px;
        overflow: auto;
    }
    .root .remark .right {
        width: 100%;
    }
    .search {
        text-align: center;
        .selectBtn {
            margin-top: 20px;
        }
    }
    .cardTitle {
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: px2rem(88);
        .title {
            font-size: 16px;
            font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
            font-weight: bold;
            text-align: left;
            color: #434343;
        }
        a {
            font-size: 12px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #989898;
        }
        .right {
            width: 70%;
            height: px2rem(88);
            text-align: right;
            color: black;
            /*line-height: px2rem(50);*/
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .iconfont {
                margin-left: px2rem(25);
            }
        }
    }
    .allTarget {
        width: 100%;
        background: $page-background;
    }
    .categoryBox {
        display: flex;
        justify-content: space-around;
        overflow-x: scroll;
        margin: px2rem(20) px2rem(20) px2rem(10);
        .category {
            padding: px2rem(11) px2rem(22);
            font-size: $default-font-size;
            border-radius: 2rem;
            background: #fff;
        }
        .categorySelect {
            background: #f38467;
            color: #fff;
        }
        .categoryItem {
            padding: 8px 15px;
            background: white;
            line-height: 3.375rem;
            text-align: center;
            color: #6d6c6c;
            font-size: px2rem(32);
        }
        .cateSelected{
            color: #0e67ed;
        }
    }
</style>
import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';
// 获取统计信息
export function getStatistic(){
    return request('/problem/getStatistic.do',"POST",{userId:SystemUtils.loginUser.id,nameCN:SystemUtils.loginUser.name})
}

// 获取问题列表
export function getProblemList(data){
    return request('/problem/getProblemList.do',"POST",data)
}

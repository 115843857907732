<template>
  <div class="card-content">
    <ion-text class="questionTitle">{{ question.text }}</ion-text>
    <ion-badge color="light" v-if="question.text =='未解决'">{{ unresolved }}</ion-badge>
    <ion-badge color="light" v-if="question.text =='解决中'">{{ underSolution }}</ion-badge>
    <ion-badge color="light" v-if="question.text =='已解决'">{{ resolved }}</ion-badge>
    <ion-badge color="light" v-if="question.text =='让步'">{{ compromise }}</ion-badge>
  </div>
</template>
<script>
import {IonBadge} from "@ionic/vue";
import {getAllStatistic} from "@/api/questionManage/questionState"
export default {
  name: 'questionState',
  props: {
    question: {
      type: Object,
      required: true,
    },
    screenConditions: Object,
  },
  components: {
    IonBadge,
  },
  data() {
    return {
      targetIndex: 0,
      islist: false, //是否显示加载中的标识
      unresolved: 0,
      underSolution: 0,
      resolved: 0,
      compromise: 0,
    };
  },
  mounted(){
    this.getList()
  },
  methods:{
    getList(){
      this.islist=true
      let params = {
        isLoading:this.targetIndex==0?1:0,isEnd:this.targetIndex==0?1:0
      }
      if(this.screenConditions) params = this.screenConditions;
      getAllStatistic(params).then(res=>{
        this.islist=false
        this.stageList = res.data.data.resultData;
        this.unresolved = this.stageList[0].unresolved;
        this.underSolution = this.stageList[0].underSolution;
        this.resolved = this.stageList[0].resolved;
        this.compromise = this.stageList[0].compromise;
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.card-content {
  width: calc(100% - 24px);
  padding: 0 15px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 5px;
  position: relative;
  ion-badge {
    width: 22px;
    height: 20px;
    margin-left: 2px;
    font-size: 10px;
    font-weight: 500;
    --padding-bottom: 0px;
    --padding-end: 0px;
    --padding-start: 0px;
    --padding-top: 4px;
  }
  .unRead{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
  }
  > div {
    padding: 15px 0 15px 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      justify-content: start;
      align-items: center;
      .questionTitle {
        font-size: 13px;
        font-family: Tensentype ChaoHeiJ, Tensentype ChaoHeiJ-Bold;
        font-weight: 700;
        color: #333333;
      }
      .identifier {
        font-size: 14px;
        font-family: DIN, DIN-Medium;
        font-weight: bold;
        text-align: left;
        color: #333333;
      }
      .imgIcon {
        width: 12px;
        height: 12px;
        margin-right: 7px;
      }
      ion-checkbox{
          margin-right: 11px;
      }
    }

    .question {
      font-size: 11px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: bold;
      text-align: left;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  > div:nth-of-type(1) {
    border-bottom: 0.5px solid #efefef;
  }
}
</style>